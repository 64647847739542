import React, { useState, useMemo, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DataTable from "examples/Tables/DataTable";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import REACT from "../../assets/images/small-logos/react.jpg";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import { fetchCourses, fetchStudents, updateStudent } from "api/dashboardapi/api";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";
import toast, { Toaster } from "react-hot-toast";

function Dashboard() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [allRows, setAllRows] = useState([]);
  useEffect(() => {
    const role = localStorage.getItem("userRole");
    if (role !== "staff") {
      navigate("/"); // Redirect to home or login if not authorized
    }
  }, [navigate]);
  const columns = [
    { Header: "Identity", accessor: "identity" },
    { Header: "Phone", accessor: "phone" },
    { Header: "Email", accessor: "email" },
    { Header: "Year", accessor: "year" },
    { Header: "Roll Number", accessor: "roll_number" },
    { Header: "Fees", accessor: "fees" },
    { Header: "Course", accessor: "course" },
    { Header: "Actions", accessor: "action" },
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const [filteredRows, setFilteredRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [SelectedCourse, setSelectedCourse] = useState(null);
  const [viewStudentModal, setViewStudentModal] = useState(false);
  const [studentDetails, setStudentDetails] = useState(null);
  const [courses, setCourses] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const staffUUID = localStorage.getItem("userId");
  const [newStudent, setNewStudent] = useState({
    name: "",
    email: "",
    phone: "",
    department: "",
    year: "",
    course_details: {
      identity: "",
    },
    fees: "",
    duration: "",
  });
  const itemsPerPage = 4;

  const calculateCourseData = (rows) => {
    if (!rows) return {};
    const courseData = {};
    let totalStudents = 0;
    let totalAmount = 0;

    rows.forEach((row) => {
      const { course, fees } = row;
      if (!courseData[course]) {
        courseData[course] = { studentCount: 0, totalFees: 0 };
      }

      courseData[course].studentCount += 1;
      courseData[course].totalFees += parseFloat(fees);
      totalStudents += 1;
      totalAmount += parseFloat(fees);
    });

    courseData.totalStudents = totalStudents;
    courseData.totalAmount = totalAmount;

    return courseData;
  };

  const courseData = useMemo(() => calculateCourseData(filteredRows), [filteredRows]);

  // Fetch courses and students data
  useEffect(() => {
    const loadData = async () => {
      try {
        const [coursesData, studentsData] = await Promise.all([
          fetchCourses(staffUUID),
          fetchStudents(staffUUID),
        ]);
        setCourses(coursesData);
        setAllRows(studentsData);
        setFilteredRows(studentsData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [staffUUID]);

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filteredData = allRows.filter((row) => row.identity.toLowerCase().includes(query));
    setFilteredRows(filteredData);
  };
  const handleClearSearch = () => {
    setSearchQuery("");
    setFilteredRows(allRows); // Reset to show all rows
  };

  const handleAddStudent = (courseIdentity) => {
    setSelectedCourse(courseIdentity);
    setCurrentCourse(courseIdentity);
    setNewStudent({
      name: "",
      email: "",
      phone: "",
      department: "",
      year: "",
      course: courseIdentity,
      fees: "",
      duration: "",
    });
    setOpenModal(true);
  };

  const currentItems = courses.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  const nextPage = () => {
    if ((currentPage + 1) * itemsPerPage < courses.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleSaveStudent = async () => {
    try {
      const result = await updateStudent(newStudent);
      if (result.status === "success") {
        const updatedRows = filteredRows.map((row) =>
          row.uuid === newStudent.uuid ? { ...row, ...newStudent } : row
        );
        setFilteredRows(updatedRows);
        setOpenModal(false);
        toast.success("Student updated successfully!");
      } else {
        throw new Error("Failed to update student");
      }
    } catch (err) {
      console.error("Error updating student:", err);
      setError(err.message);
      toast.error(`Error: ${err.message}`);
    }
  };

  const handleEditStudent = (student) => {
    setNewStudent(student);
    setOpenModal(true);
  };

  const handleViewStudent = (student) => {
    setStudentDetails(student);
    setViewStudentModal(true);
  };

  const enhancedRows = useMemo(() => {
    return filteredRows.map((student) => ({
      ...student,
      course: student.course_details?.identity || "N/A",
      action: (
        <>
          <IconButton onClick={() => handleEditStudent(student)} style={{ color: "#1976d2" }}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleViewStudent(student)} style={{ color: "#1976d2" }}>
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    }));
  }, [filteredRows]);

  return (
    <MDBox
      width="100%"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      sx={{ px: 3, py: 3 }}
    >
      <DashboardNavbar />
      <Toaster />
      <br />
      <MDBox flex="1">
        <Grid container spacing={3}>
          {currentItems.map((course) => (
            <Grid key={course.uuid} item xs={12} md={6} lg={3}>
              <MDBox mb={1.5} position="relative">
                {course.no_of_students > 0 ? (
                  <ComplexStatisticsCard
                    color="info"
                    // icon={REACT}
                    title={course.identity}
                    count={`${course.no_of_students} students`}
                    total={{
                      color: "success",
                      amount: "Total amount",
                      label: `${course.total_fees}`,
                    }}
                  />
                ) : (
                  <Card sx={{ textAlign: "center", p: 3 }}>
                    <MDTypography variant="h6" color="textSecondary">
                      {course.identity}
                    </MDTypography>
                    <MDTypography variant="body2" color="textSecondary">
                      Locked
                    </MDTypography>
                    <Box
                      sx={{
                        width: 40,
                        height: 40,
                        borderRadius: "50%",
                        backgroundColor: "grey.300",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "0 auto",
                      }}
                    >
                      <LockIcon style={{ color: "grey.700" }} />
                    </Box>
                  </Card>
                )}
              </MDBox>
            </Grid>
          ))}
        </Grid>

        {/* Navigation buttons */}
        <MDBox display="flex" justifyContent="space-between" mt={2}>
          <MDTypography onClick={prevPage} sx={{ cursor: "pointer", color: "primary.main" }}>
            Previous
          </MDTypography>
          <MDTypography onClick={nextPage} sx={{ cursor: "pointer", color: "primary.main" }}>
            Next
          </MDTypography>
        </MDBox>
        <MDBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Card>
                <MDBox pt={3}>
                  <MDTypography variant="h5" align="center" mb={2}>
                    Students List
                    {/* Search Bar */}
                    <MDBox display="flex" justifyContent="flex-start" mb={2}>
                      <TextField
                        label="Search by Students name"
                        variant="outlined"
                        size="small" // Adjusts the size of the TextField
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                          endAdornment: searchQuery && (
                            <IconButton onClick={handleClearSearch}>
                              <CloseIcon />
                            </IconButton>
                          ),
                        }}
                        sx={{ width: "270px" }} // Fixed width for the search box
                      />
                    </MDBox>
                  </MDTypography>

                  {loading ? (
                    <MDTypography variant="h6" color="textSecondary" align="center">
                      Loading students...
                    </MDTypography>
                  ) : error ? (
                    <MDTypography variant="h6" color="error" align="center">
                      Error: {error}
                    </MDTypography>
                  ) : filteredRows.length === 0 ? (
                    <MDTypography variant="h6" color="textSecondary" align="center">
                      No students available
                    </MDTypography>
                  ) : (
                    <DataTable table={{ columns, rows: enhancedRows }} isSorted={false} />
                  )}
                </MDBox>
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {/* Modal for adding students */}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <MDTypography variant="h6" mb={2}>
            Edit Student
          </MDTypography>
          <TextField
            label="Student Name"
            fullWidth
            value={newStudent.identity}
            onChange={(e) => setNewStudent({ ...newStudent, identity: e.target.value })}
            margin="normal"
          />
          <TextField
            label="Student Email"
            fullWidth
            value={newStudent.email}
            onChange={(e) => setNewStudent({ ...newStudent, email: e.target.value })}
            margin="normal"
          />
          <TextField
            label="Phone Number"
            fullWidth
            value={newStudent.phone}
            onChange={(e) => setNewStudent({ ...newStudent, phone: e.target.value })}
            margin="normal"
          />
          <TextField
            label="Roll Number"
            fullWidth
            value={newStudent.roll_number}
            onChange={(e) => setNewStudent({ ...newStudent, roll_number: e.target.value })}
            margin="normal"
          />
          <TextField
            label="Department"
            fullWidth
            value={newStudent.department}
            onChange={(e) => setNewStudent({ ...newStudent, department: e.target.value })}
            margin="normal"
          />
          <TextField
            label="Year"
            fullWidth
            value={newStudent.year}
            onChange={(e) => setNewStudent({ ...newStudent, year: e.target.value })}
            margin="normal"
          />
          {/* <TextField
            label="Course"
            fullWidth
            value={newStudent.course_details.identity}
            onChange={(e) => setNewStudent({ ...newStudent, course: e.target.value })}
            margin="normal"
          /> */}
          <TextField
            label="Fees"
            fullWidth
            value={newStudent.fees}
            onChange={(e) => setNewStudent({ ...newStudent, fees: e.target.value })}
            margin="normal"
          />
          <Button
            onClick={handleSaveStudent}
            fullWidth
            variant="contained"
            sx={{ color: "white !important" }}
            color="primary"
          >
            Save Changes
          </Button>
        </Box>
      </Modal>
      {/* View Student Details Modal */}
      <Modal open={viewStudentModal} onClose={() => setViewStudentModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <MDTypography variant="h6" mb={2}>
            View Student Details
          </MDTypography>
          <TextField
            label="Student Name"
            fullWidth
            value={studentDetails?.identity}
            disabled
            margin="normal"
          />
          <TextField
            label="Student Email"
            fullWidth
            value={studentDetails?.email}
            disabled
            margin="normal"
          />
          <TextField
            label="Roll Number"
            fullWidth
            value={studentDetails?.roll_number}
            disabled
            margin="normal"
          />
          <TextField
            label="Phone Number"
            fullWidth
            value={studentDetails?.phone}
            disabled
            margin="normal"
          />
          <TextField
            label="Department"
            fullWidth
            value={studentDetails?.department}
            disabled
            margin="normal"
          />
          <TextField label="Year" fullWidth value={studentDetails?.year} disabled margin="normal" />

          <TextField label="Fees" fullWidth value={studentDetails?.fees} disabled margin="normal" />
          {/* <TextField
            label="Course"
            fullWidth
            value={newStudent.course_details ? newStudent.course_details.identity : ""}
            onChange={(e) =>
              setNewStudent({
                ...newStudent,
                course_details: {
                  ...newStudent.course_details, // Retain other course details if there are any
                  identity: e.target.value,
                },
              })
            }
            margin="normal"
          /> */}

          <Button
            variant="contained"
            color="primary"
            sx={{ color: "white !important" }}
            onClick={() => setViewStudentModal(false)}
          >
            Close
          </Button>
        </Box>
      </Modal>
      <Footer />
    </MDBox>
  );
}

export default Dashboard;
