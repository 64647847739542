// layouts/tables/data/authorsTableData.js

const authorsTableData = () => {
  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Department", accessor: "department" },
    { Header: "Year", accessor: "year" },
    { Header: "Course", accessor: "course" },
    { Header: "Fee", accessor: "fees" },
    { Header: "Actions", accessor: "action" },
  ];

  const rows = [
    {
      name: "Alice",
      phone: "123-456-7890",
      email: "alice@example.com",
      department: "CSE",
      year: "I",
      course: "React JS",
      fees: "500",
      duration: "11-OCT-24 to 21-OCT-24",
    },
    {
      name: "Bob",
      phone: "123-456-7891",
      email: "bob@example.com",
      department: "CSE",
      year: "II",
      course: "React JS",
      fees: "2500",
    },
    {
      name: "David",
      phone: "123-456-7893",
      email: "david@example.com",
      department: "EEE",
      year: "II",
      course: "Python",
      fees: "2500",
    },
    {
      name: "Eve",
      phone: "123-456-7894",
      email: "eve@example.com",
      department: "EEE",
      year: "I",
      course: "Python",
      fees: "2320",
    },
    {
      name: "Frank",
      phone: "123-456-7895",
      email: "frank@example.com",
      department: "MECH",
      year: "III",
      course: "HTML & CSS",
      fees: "200",
    },
    {
      name: "Grace",
      phone: "123-456-7896",
      email: "grace@example.com",
      department: "MECH",
      year: "IV",
      course: "HTML & CSS",
      fees: "2500",
    },
    {
      name: "Hannah",
      phone: "123-456-7897",
      email: "hannah@example.com",
      department: "CSE",
      year: "II",
      course: "UI/UX",
      fees: "2590",
    },
    {
      name: "Ivy",
      phone: "123-456-7898",
      email: "ivy@example.com",
      department: "CSE",
      year: "III",
      course: "UI/UX",
      fees: "2500",
    },
    {
      name: "Jack",
      phone: "123-456-7899",
      email: "jack@example.com",
      department: "EEE",
      year: "IV",
      course: "JavaScript",
      fees: "2500",
    },
    {
      name: "Katherine",
      phone: "123-456-7900",
      email: "katherine@example.com",
      department: "EEE",
      year: "II",
      course: "JavaScript",
      fees: "2500",
    },
    {
      name: "Liam",
      phone: "123-456-7901",
      email: "liam@example.com",
      department: "MECH",
      year: "III",
      course: "JavaScript",
      fees: "2500",
    },
    {
      name: "Megan",
      phone: "123-456-7902",
      email: "megan@example.com",
      department: "CSE",
      year: "I",
      course: "Data Science",
      fees: "2500",
    },
    {
      name: "Paul",
      phone: "123-456-7905",
      email: "paul@example.com",
      department: "EEE",
      year: "II",
      course: "Machine Learning",
      fees: "2500",
    },
    {
      name: "Quincy",
      phone: "123-456-7906",
      email: "quincy@example.com",
      department: "MECH",
      year: "IV",
      course: "Full Stack Development",
      fees: "2500",
    },
  ];

  return { columns, rows };
};

export default authorsTableData;
