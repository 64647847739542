import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import StudentDashboard from "layouts/StudentDashboard";

import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: SignIn,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/student-registration",
    component: SignUp,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: Dashboard,
    protected: true,
    allowedRole: "staff",
  },
  {
    type: "collapse",
    name: "Student Dashboard",
    key: "studentDashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/student-dashboard",
    component: StudentDashboard,
    protected: true,
    allowedRole: "student",
  },
  {
    type: "collapse",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: Tables,
  },
];

export default routes;
