import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ component: Component, allowedRole }) => {
  const isAuth = localStorage.getItem("userRole") !== null;
  const role = localStorage.getItem("userRole");

  // Redirect to sign-in if not authenticated
  if (!isAuth) {
    return <Navigate to="/sign-in" replace />;
  }

  // Redirect to unauthorized page (or homepage) if role mismatch
  if (allowedRole && role !== allowedRole) {
    return <Navigate to="/sign-in" replace />;
  }

  // Render the allowed component
  return <Component />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  allowedRole: PropTypes.string,
};

export default ProtectedRoute;
