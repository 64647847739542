import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import PropTypes from "prop-types"; // Import PropTypes

const ImagePreviewModal = ({ open, imageUrl, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Image Preview</DialogTitle>
      <DialogContent>
        <img
          src={imageUrl}
          alt="Preview"
          style={{ width: "100%", height: "auto", borderRadius: "10px" }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Add PropTypes validation for the props
ImagePreviewModal.propTypes = {
  open: PropTypes.bool.isRequired, // The modal open state (boolean)
  imageUrl: PropTypes.string.isRequired, // The URL of the image to display (string)
  onClose: PropTypes.func.isRequired, // Function to close the modal (function)
};

export default ImagePreviewModal;
