import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import ProtectedRoute from "./ProtectedRoute";
import routes from "./routes";
import theme from "assets/theme";

export default function App() {
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        if (route.protected) {
          return (
            <Route
              key={route.key}
              path={route.route}
              element={
                <ProtectedRoute component={route.component} allowedRole={route.allowedRole} />
              }
            />
          );
        }

        return <Route exact key={route.key} path={route.route} element={<route.component />} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/sign-in" replace />} />
      </Routes>
    </ThemeProvider>
  );
}
